<template>
  <div class="box-style" v-if="loading">
    <loading-spinner />
  </div>
  <div class="" v-else>
    <div class="box-style">
      <div class="grid grid-cols-3 gap-6">
        <div class="col-span-3 lg:col-span-2">
          <fieldwork-view-student-details
            v-if="checkAdmin()"
            :student="fieldwork.student"
          />
          <fieldwork-detail :fieldwork="fieldwork" />
        </div>
        <div class="">
          <entry-messages :id="this.fieldwork.fieldwork_id" />
        </div>
      </div>
      <div v-if="isAdm() || isSaj()">
        <div class="profile_box relative">
          <div class="thirdhead-text">Manage Entry</div>
          <div>
            Approve, reject, return, or delete this fieldwork entry. Deletion is
            permanent, and cannot be undone.
          </div>
          <div class="pt-4">
            <ul class="grid grid-cols-4 gap-10 w-3/4">
              <li
                class="relative inline-block"
                v-for="(status, key) in fieldwork_statuses"
                :key="key"
              >
                <input
                  class="sr-only peer"
                  type="radio"
                  name="type"
                  :title="status.title"
                  :id="status.value"
                  :value="status.value"
                  v-model="admin_event_status_update"
                />
                <label
                  :class="{
                    'w-full block border rounded-full text-white pt-1 text-center h-8 hover:cursor-pointer': true,
                    'border-alert-success-heavy bg-alert-success peer-checked:bg-alert-success-heavy peer-checked:text-white':
                      status.value === 'APPROVED',
                    'border-yellow-heavy bg-yellow text-yellow-heavy peer-checked:bg-yellow-heavy peer-checked:text-white':
                      status.value === 'RETURNED',
                    'border-orange-heavy bg-orange peer-checked:bg-orange-heavy peer-checked:text-white':
                      status.value === 'REJECTED',
                    'border-alert-danger bg-required peer-checked:bg-alert-danger peer-checked:text-white':
                      status.value === 'DELETED',
                    'border-dark-gray bg-steel-gray peer-checked:bg-steel-gray peer-checked:text-dark-gray':
                      !status.value,
                  }"
                  :for="status.value"
                >
                  <span class="inline-block px-3">
                    {{ status.title }}
                  </span>
                  <font-awesome-icon :icon="status.icon" />
                </label>
              </li>
            </ul>
          </div>
          <modal
            v-if="show_submit_modal"
            class="absolute w-full top-5"
            label="Are you sure you want to submit these changes?"
            submit_btn_text="Yes"
            show_cancel
            cancel_btn_text="No, continue editing"
            @submit="updateEntry"
            @close="closeModal"
          >
            <template v-slot:content>
              <p>
                {{
                  this.error
                    ? this.error
                    : `You are about to submit these changes to the
                          database. Please confirm that you have made the correct
                          changes. Deletion cannot be undone!`
                }}
              </p>
            </template>
          </modal>
        </div>
        <div class="flex justify-end pt-3">
          <button
            class="inline-block py-1 px-5 bg-purple hover:bg-dark-purple rounded-full text-white"
            @click="show_submit_modal = true"
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Modal from "@/components/modal/Modal";
import { put, get } from "@/composables/httpUtil";
import FieldworkDetail from "@/components/reusable-components/FieldworkDetail";
import EntryMessages from "@/components/forms/EntryMessages";
import LoadingSpinner from "@/components/reusable-components/LoadingSpinner";
import FieldworkViewStudentDetails from "@/views/student/fieldwork/subcomponents/view-subcomponents/FieldworkViewStudentDetails";
import { checkAdmin } from "@/composables/tokenUtils";
import { isAdm, isSra, isFac, isSaj } from "@/composables/tokenUtils";

export default {
  name: "AdminFieldworksView",
  components: {
    LoadingSpinner,
    Modal,
    FieldworkDetail,
    EntryMessages,
    FieldworkViewStudentDetails,
  },
  async mounted() {
    this.loading = true;
    await get(`admin/fieldworks/${this.$route.params.id}`)
      .then((fieldwork) => (this.fieldwork = fieldwork))
      .finally(() => (this.loading = false));
  },
  data() {
    return {
      loading: true,

      fieldwork: null,

      admin_message: "",
      admin_event_status_update: "",
      show_submit_modal: false,
      error: "",
      fieldwork_statuses: [
        // { value: "TODO", title: "Submitted", icon: "" },
        {
          value: "RETURNED",
          title: "Returned",
          icon: "reply",
        },
        {
          value: "APPROVED",
          title: "Approved",
          icon: "check",
        },
        {
          value: "REJECTED",
          title: "Rejected",
          icon: "ban",
        },
        {
          value: "DELETED",
          title: "Deleted",
          icon: "trash",
        },
      ],
    };
  },
  methods: {
    put,
    get,
    checkAdmin,
    isAdm,
    isSra,
    isFac,
    isSaj,
    format: function (item_content, item_type) {
      if (item_content === "date") {
        return item_type.toLocaleDateString("en-US", {
          month: "long",
          day: "numeric",
          year: "numeric",
        });
      } else {
        return item_type;
      }
    },
    updateEntry: function () {
      if (this.validate()) {
        let fieldwork = { ...this.fieldwork };
        fieldwork.event_status = this.admin_event_status_update;
        this.put(
          `admin/fieldworks/${this.fieldwork.fieldwork_id}`,
          fieldwork
        ).then((fieldwork) => {
          this.fieldwork = fieldwork;
          this.$router.push("/admin/fieldworks");
        });
      } else {
        this.error = "Please select a valid event status before submitting!";
        console.log("error (updateEntry):", this.error);
      }
    },
    validate: function () {
      // check if all required fields are filled admin_event_status_update
      return ["APPROVED", "REJECTED", "RETURNED", "DELETED"].includes(
        this.admin_event_status_update
      );
    },
    closeModal: function () {
      this.show_submit_modal = false;
      this.error = "";
    },
  },
};
</script>
