<template>
  <div>
    <div class="text-2xl">
      <h2 id="student-name" class="inline-block secondhead-text">
        Student:
      </h2>
      {{ student.firstname }} {{ student.lastname }}
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "FieldworkViewStudentDetails",
  props: {
    student: {
      type: Object,
      required: false,
    }
  },
  computed: {
    ...mapGetters(["student_profile"]),
  }
};
</script>

<style scoped>

</style>
